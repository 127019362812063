.wrap {
  align-items: center;
  display: flex;
  justify-content: center;
}

.card {
  background: linear-gradient(0.25turn, #3f87a6, #ebf8e1, #f69d3c);
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgba(255, 255, 255, 0.1),
    0 1px 2px 0 rgba(255, 255, 255, 0.06);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 2rem;
  width: 85%;
  /* margin: auto; */
  margin: 2rem auto;
  align-items: center;
}

.title {
  color: rgb(0, 0, 0);
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem;
}

.img_wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.content_wrap iframe {
  border-radius: 4px;
  object-fit: cover;
  width: 500px;
  height: 280px;
}

.content_wrap img {
  border-radius: 4px;
  object-fit: cover;
  max-width: 500px;
}

.content_wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.descr_link_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.description_wrap {
  display: flex;
  align-items: center;
  width: 70%;
  border: 1px solid black;
  border-radius: 10px;
  margin: 1rem;
  background-color: rgba(175, 192, 206, 0.253);
}

.description {
  color: rgb(0, 0, 0);
  font-size: 1rem;
  margin: 1rem;
}

.link {
  background-color: rgb(0, 0, 0);
  border-radius: 0px;
  color: rgb(245, 245, 245);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  border-color: black;
  border: 1px solid black;
  margin: 0.5rem;
  transition: all 0.3s ease-in-out;
  transform: scale(1);
  box-shadow: 2px 2px 8px rgb(0, 0, 0, 0.5);
}

.link:hover {
  transform: scale(1);
  background-color: rgba(0, 0, 0, 0);
  color: rgb(0, 0, 0);
}

.link:active {
  transform: scale(0.9);
}

.link_disabled {
  /* background-color: rgb(0, 0, 0); */
  border-radius: 4px;
  color: rgb(121, 121, 121);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: flex;
  border: 1px solid rgb(121, 121, 121);
  margin: 0.5rem;
  cursor: not-allowed;
}

@media screen and (max-width: 1000px) {
  .card {
    width: 90%;
    margin: 1.5rem 30px;
  }

  .description_wrap {
    width: 100%;
  }

  .content_wrap {
    flex-direction: column;
  }

  .img {
    max-width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .card {
    width: 90%;
    margin: 1.5rem 10px;
  }

  .content_wrap img {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  .content_wrap iframe {
    width: auto;
    /* max-width: 100%; */
    height: auto;
  }
}

@media screen and (max-width: 360px) {
  .content_wrap img {
    max-width: 290px;
    height: auto;
  }

  .content_wrap iframe {
    max-width: 290px;
    height: auto;
  }

  .card {
    padding: 10px;
  }
}
