.wrap {
  margin-bottom: 2rem;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.title h1 {
  color: rgb(245, 245, 245);
  font-size: 30px;
}

.cards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
