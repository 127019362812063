a {
  display: inline-block;
  margin: 10px;
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  font-weight: bold;
  transition: all 0.3s ease-in-out;
}

a:link {
  text-decoration: none;
  color: white;
}

a:visited {
  color: white;
}

.logo1 {
  width: 23px;
  height: 23px;
  margin-top: 2px;
  margin-left: 10px;
}

.logo2 {
  width: 21px;
  height: 21px;
  margin-top: 2px;
  margin-left: 10px;
}

.wrap {
  min-height: 100%;
}

section {
  width: 100%;
}

.navbar_section {
  position: sticky;
  top: 0;
  z-index: 100;
  max-width: 100%;
  height: 0px;

}

/* .wrap_nav {
  border-bottom: 2px solid #000000;
} */

.navbar {
  background-color: rgb(0, 0, 0);
  /* background: linear-gradient(rgb(0, 0, 0), rgb(37, 17, 2), rgb(102, 45, 7)); */
  box-shadow: 1px 1px 10px 2px rgb(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  height: 40px;
  border-bottom: 1px solid rgb(238, 106, 18);
}

.navbar_title {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}

.navlink {
  transition: all 0.3s ease-in-out;
  /* background-color: rgb(0, 0, 0 0.85); */
  color: rgb(245, 245, 245);
  padding: 2px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
}

.navlink:hover {
  transition: all 0.2s ease-in-out;
  background-color: rgb(238,
  106,
  18);
  color: rgb(0, 0, 0);
}

.navlink:active {
  transition: all 0.15s ease-in-out;
  background-color: rgb(245, 245, 245);
  color: rgb(0, 0, 0);
  transform: scale(0.9);
}

.navlink_logo:hover {
  transition: all 0.15s ease-in-out;
  transform: scale(1.2);
}

.navbar_links {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
}

.sub_wrap1 {
  background-color: rgb(245, 245, 245);
  padding-top: 1px;
}

.sub_wrap1 h1 {
  color: rgb(0, 0, 0);
}

.sub_wrap2 {
  padding-top: 1px;
  background-color: rgb(0, 0, 0);
  padding-bottom: 1rem;
  align-items: center;
  display: flex;
}

.sub_wrap3 {
  display: flex;
  min-height: 50vh;
  background-color: rgb(245, 245, 245);
  align-items: center;
  padding-top: 1px;
  justify-content: center;
}

.sub_wrap1,
.sub_wrap2,
.sub_wrap3 {
  padding: 20px 0 0;
}

.footer {
  background-color: rgb(0, 0, 0);
  /* background: linear-gradient(rgb(102, 45, 7), rgb(37, 17, 2), rgb(0, 0, 0)); */
  color: rgb(245, 245, 245);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  font-weight: bold;
  padding-top: 3px;
  padding-bottom: 3px;
}

@media screen and (max-width: 691px) {
  .navbar_section {
    /* height: 35px; */
    flex-direction: row;
  }

  .navbar {
    flex-direction: column;
    height: 70px;
  }

  /* .navbar_title {
    flex-direction: column;
  } */

  .navbar_links {
    margin-top: -15px;
  }

  .sub_wrap1,
  .sub_wrap2,
  .sub_wrap3 {
    padding: 45px 0 0;
  }
}

@media screen and (max-width: 385px) {
  .navlink_logo {
    display: none;
  }
}
