.transition-enter {
  opacity: 0;
  transform: scale(0.9);
}
.transition-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.transition-exit {
  opacity: 1;
}
.transition-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.transition {
  margin: 20px 0 28px;
  height: 180px;
  width: 330px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
