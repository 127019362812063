.wrap {
  margin: 0 0 4rem;
}

.title h1 {
  color: rgb(0, 0, 0);
  font-size: 30px;
  margin-bottom: 55px;
}

.email {
  cursor: pointer;
}

label {
  font-size: 1rem;
  font-weight: 500;
  color: rgb(245, 245, 245);
  letter-spacing: 0.1em;
  margin-bottom: 0.5rem;
  min-width: 60px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;
  width: 500px;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin: auto;
  background-color: rgb(0, 0, 0);
  border-radius: 7px;
  box-shadow: black 0px 0px 10px;
}

.link {
  color: rgb(245, 245, 245) !important;
  font-size: 20px !important ;
  align-items: center;
  text-align: left;
  display: flex;
  flex-direction: row;
  width: 100%;
}

.link:hover {
  color: rgb(238, 106, 18) !important;
}

.icon {
  width: 30px;
  height: 30px;
  margin-right: 20px;
}

.icon2 {
  width: 33px;
  height: 33px;
  margin-right: 10px;
}

.form_input_wrap {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 10px;
}

.form input,
textarea {
  outline: 0;
  height: 25px;
  width: 275px;
  /* border-radius: 4px; */
}

textarea {
  min-height: 35px;
  resize: vertical;
}

.btn {
  background-color: rgb(245, 245, 245);
  border-radius: 4px;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  /* border-color: black; */
  border: 1px solid rgb(245, 245, 245);
  margin: 0.5rem;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0);
  color: rgb(245, 245, 245);
}

.btn:active {
  transform: scale(0.9);
}

@media screen and (max-width: 600px) {
  .form {
    width: 88%;
  }
}

@media screen and (max-width: 425px) {
  .form {
    width: 92%;
  }
}

@media screen and (max-width: 400px) {
  .form {
    width: 90%;
  }
  .txt {
    font-size: 16px;
  }
}

@media screen and (max-width: 360px) {
  .form {
    width: 90%;
  }
  .txt {
    font-size: 14px;
  }
}
