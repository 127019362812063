body {
  margin: 0;
  font-family: Orbitron, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  outline: none;
  scroll-behavior: smooth;
}

@font-face {
  font-family: Cyberverse;
  src: local("/fonts/cyberverse.otf"), format("ttf");
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
li,
ul,
ol,
form,
input,
textarea,
button {
  font-family: Orbitron;
  font-weight: bold;
  word-spacing: 3px;
  letter-spacing: 1px;
}
