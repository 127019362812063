.wrap {
  background-color: rgb(245, 245, 245);
}

.about_title {
  font-size: 32px;
  font-weight: bold;
  padding-top: 25px;
}

.img_txt_wrap {
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;
}

.profile {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  transition: all 0.5s ease-in-out;
  height: 100%;
}

.img {
  height: 400px;
  width: 300px;
  object-fit: cover;
  border-radius: 10px 10px 0 0;
  padding-bottom: -100px;
  transition: all 0.5s ease-in-out;
}

.infocard {
  display: flex;
  flex-direction: column;
  text-align: start;
  height: 100%;
  width: 278px;
  border: 1px solid rgb(0, 0, 0);
  border-top: none;
  border-radius: 0 0 10px 10px;
  padding: 10px;
}

.infocard_item {
  margin: 0 0 5px 10px;
}

.img:hover {
  background-color: rgb(238, 106, 18);

  /* border-radius: 20px; */
}

.txt_wrap {
  display: flex;
  align-content: space-around;
  justify-content: space-around;
  height: 100%;
  flex-direction: column;
  width: 55%;
}

.txt {
  display: flex;
  /* color: rgb(47, 101, 218); */
  color: rgb(238, 106, 18);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  justify-content: center;
}

.cv {
  /* transition: all ease-in-out 0.5s; */
  margin: 20px 0 28px;
  height: 180px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* align-content: space-around; */
  justify-content: center;
}

.btn {
  margin: 25px 0 15px;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  background-color: black;
  color: rgb(238, 106, 18) !important;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  width: 250px;
  box-shadow: 2px 2px 8px rgb(0, 0, 0, 0.5);
  transition: all ease-in-out 0.15s;
}

.btn:hover {
  background-color: rgb(238, 106, 18);
  color: black !important;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.5);
}

.btn:active {
  background-color: rgba(238, 106, 18, 0.247);
}

.btnX {
  align-self: flex-end;
  border: none;
  cursor: pointer;
  border-radius: 999px;
  background-color: black;
  color: rgb(238, 106, 18) !important;
  font-size: 20px;
  font-weight: lighter;
  text-align: center;
  box-shadow: 2px 2px 8px rgb(0, 0, 0, 0.5);
  transition: all ease-in-out 0.15s;
  padding-top: 2px;
  padding-bottom: 2px;
}

.btnX:hover {
  background-color: rgb(238, 106, 18);
  color: black !important;
  box-shadow: 2px 2px 5px rgb(0, 0, 0, 0.5);
}

.btnX:active {
  background-color: rgba(238, 106, 18, 0.247);
}

.skills {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.skills_title {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.skills_icons {
  display: inline-block;
  justify-content: space-around;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 30px;
  margin-right: 50px;
  margin-left: 50px;
}

.skills_icons a:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 900px) {
  .img_txt_wrap {
    flex-direction: column;
  }

  .img_wrap {
    width: 100%;
  }

  .txt_wrap {
    width: 100%;
  }

  .skills {
    width: 100%;
  }
}
